const GUN = require('gun/gun'); // in React
require('gun/sea');
const SEA = GUN.SEA;

export async function createRecord(slug, json, parentId, gun, user) {
  json['type'] = slug;
  json['parent'] = parentId;
  json['owner'] = user.pub;
  json['timestamp'] = (new Date()).getTime();

  var data = JSON.stringify(json);

  // Generate unique record id
  var hash = await SEA.work(data, null, null, { name: "SHA-256" });

  // Create record in the User Space
  await gun.user().auth(user);
  gun.get('~' + user.pub).get(slug).get(hash).put(data);

  // Add reference to slug topic
  gun.get("#").get(hash).put(data)
  return hash;
}


export async function createForum(name, description, gun, user) {
  return createRecord("forums", { name: name, description: description }, null, gun, user)
}

export async function createPost(forumId, title, description, gun, user) {
  return createRecord("posts", { title: title, description: description }, forumId, gun, user)
}

export async function createComment(parentId, text, gun, user) {
  return createRecord("comments", { comment: text }, parentId, gun, user)
}

export async function createRating(parentId, like, gun, user) {
  return createRecord("likes", { like: like }, parentId, gun, user)
}

export async function createFakeData(gun, user) {
  var num_forums = 100;
  var num_posts = 1000;
  var num_comments = 1000;
  var num_likes = 1000;

  for (var i = 0; i < num_forums; i++) {
    console.log("Forum", i)
    var forum_id = await createForum(`Test${i}`, "Test Description Lorem ipsum", gun, user);
    for (var j = 0; j < num_posts; j++) {
      var post_id = await createPost(forum_id, `Post${j}`, "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.", gun, user);
      console.log("Post", j)
      for (var k = 0; k < num_comments; k++) {
        var comment_id = await createForum(post_id, "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.", gun, user);

        for (var l = 0; l < num_likes; l++) {
          var comment_id = await createRating(comment_id, true, gun, user);
        }
      }
    }
  }
}


