import logo from './logo.png';
import './App.css';
import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ReactMarkdown from 'react-markdown'
import { Drawer, List, ListItem, ListItemText } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, useParams, Link } from 'react-router-dom';
import QRCode from "react-qr-code";


import ForumRouter from './ForumRouter.js'

const GUN = require('gun/gun'); // in React
require('gun/sea');
const SEA = GUN.SEA;
let gun = GUN("https://gunrelay.zclarke.repl.co/gun");
window.gun = gun;

let DATA = {};
window.DATA = DATA;



const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});



function wrappedUpdateData(cb) {
  async function updateData(dict) {
    // TODO- pull this data from the user space so it is validated
    for (var key in dict) {
      if (typeof dict[key] === 'string') {
        DATA[key] = JSON.parse(dict[key]);
      }
    }
    console.log("Data synced");
    formatData(cb)
  }
  return updateData;
}




async function formatData(setForums) {
  // First loop through and assign any children to parents
  for (var key in DATA) {
    try {
      var datablob = DATA[key];
      datablob['hash'] = key;
      if (datablob.parent) {
        var parent = DATA[datablob.parent];
        if (parent) {
          if (!parent.child) {
            parent.child = {};
          }
          parent.child[key] = datablob;
        }
      }
    } catch {

    }

  }

  // Initialize buckets to sort records
  var forums = {};



  for (var key in DATA) {
    try {
      var datablob = DATA[key];
      //Ensure we copy the nested data structure so no data is overwritten
      if (datablob.type == "forums") {
        //Map children to posts
        forums[key] = datablob;
        forums[key].posts = [];
        for (var ckey in forums[key].child) {
          var cblob = forums[key].child[ckey];
          cblob.forum = datablob.name;
          if (cblob.type == 'posts') {
            forums[key].posts.push(cblob);
          }
        }
        forums[key].posts = forums[key].posts.sort(function (a, b) {
          return b.rating - a.rating;
        })
      }

      if (datablob.type == "posts") {
        //Map children to comments/likes
        datablob.comments = [];
        datablob.upvotes = 0;
        datablob.downvotes = 0;

        for (var ckey in datablob.child) {
          var cblob = datablob.child[ckey];
          if (cblob.type == 'comments') {
            datablob.comments.push(cblob);
          }
          if (cblob.type == 'likes') {
            if (cblob.like) {
              datablob.upvotes += 1;
            } else {
              datablob.downvotes += 1;
            }
          }

        }
        datablob.comments = datablob.comments.sort(function (a, b) {
          return b.rating - a.rating;
        })


        datablob.rating = datablob.upvotes - datablob.downvotes;
      }
      if (datablob.type == "comments") {
        //Map children to comments/likes
        datablob.comments = [];
        datablob.upvotes = 0;
        datablob.downvotes = 0;

        for (var ckey in datablob.child) {
          var cblob = datablob.child[ckey];
          if (cblob.type == 'comments') {
            datablob.comments.push(cblob);
          }
          if (cblob.type == 'likes') {
            if (cblob.like) {
              datablob.upvotes += 1;
            } else {
              datablob.downvotes += 1;
            }
          }

        }

        datablob.rating = datablob.upvotes - datablob.downvotes

        datablob.comments = datablob.comments.sort(function (a, b) {
          return b.rating - a.rating;
        })

      }
    } catch {

    }


  }

  setForums(Object.values(forums));
}




function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const [userDrawerOpen, setUserDrawerOpen] = useState(false);

  const toggleUserDrawer = () => {
    setUserDrawerOpen(!userDrawerOpen);
  };



  const [forums, setForums] = useState([]);
  const [user, setUser] = useState(null);

  const [createForumName, setCreateForumName] = useState(null);
  const [createForumDescription, setCreateForumDescription] = useState(null);


  const [createPostTitle, setCreatePostTitle] = useState({});
  const updateSetPostTitle = (key, value) => {
    setCreatePostTitle(prevState => ({
      ...prevState,
      [key]: value
    }));
  };
  const [createPostDescription, setCreatePostDescription] = useState({});
  const updateCreatePostDescription = (key, value) => {
    setCreatePostDescription(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const [createCommentBody, setCreateCommentBody] = useState({});
  const updateCreateCommentBody = (key, value) => {
    setCreateCommentBody(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  useEffect(() => {
    async function fetchData() {
      //Initialize User
      if (localStorage.getItem('user')) {
        setUser(JSON.parse(localStorage.getItem('user')));
      } else {
        const user_data = await GUN.SEA.pair();
        setUser(user_data);
        localStorage.setItem("user", JSON.stringify(user_data));
      }


      //Initialize data pipeline connection
      gun.get("#").on(wrappedUpdateData(setForums))

    }
    fetchData();
  }, []);


  return (
    <Router>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <main>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={toggleDrawer}
                >
                  <MenuIcon />
                </IconButton>
                <img src={logo} alt="p2peddit logo" style={{ width: "50px", borderRadius: "50%", marginRight: "20px" }} />
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                <Link to="/" style={{textDecoration:'none'}}>
                  p2peddit.com
                  </Link>
                </Typography>
                <p><i>{(user || { pub: "" }).pub.slice(0, 10)}</i></p>
                <img onClick={toggleUserDrawer} style={{ width: "50px", margin: "10px" }} src={`https://source.boringavatars.com/beam/120/${(user || { pub: "" }).pub}?colors=264653,2a9d8f,e9c46a,f4a261,e76f51`} alt="User profile" />
              </Toolbar>
            </AppBar>

            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer} >
              <h2>Forums</h2>
              <ListItem>
                    <Link to="/" >
                      <ListItemText primary="Home" />
                    </Link>
                  </ListItem>
              <List>
                {forums.map(function(forum){
                  return (
                  <ListItem>
                    <Link to={`/forum/${encodeURIComponent(forum.hash)}`} style={{textDecoration:"none"}} >
                    <ListItemText primary={forum.name} />
                    </Link>
                  </ListItem>
                  );
                })}
                
              </List>
            </Drawer>


            <Drawer anchor="right" open={userDrawerOpen} onClose={toggleUserDrawer} style={{minWidth:"50%",padding:"100px"}}>
              <p><i>{(user || { pub: "" }).pub.slice(0, 10)}</i><img onClick={toggleUserDrawer} style={{ width: "50px", margin: "10px" }} src={`https://source.boringavatars.com/beam/120/${(user || { pub: "" }).pub}?colors=264653,2a9d8f,e9c46a,f4a261,e76f51`} alt="User profile" /></p>
                
              <QRCode value={JSON.stringify(user)} style={{padding:"10px"}}/>
            </Drawer>

            <Routes>
              <Route exact path="/" element={<ForumRouter forums={forums} user={user} gun={gun}/>} />
              <Route path="/forum/:forum_id" element={<ForumRouter forums={forums} user={user} gun={gun}/>} />
              <Route path="/post/:post_id" element={<ForumRouter forums={forums} user={user} gun={gun}/>} />
              <Route path="/user/:user_id" element={<ForumRouter forums={forums} user={user} gun={gun}/>} />

            </Routes>


          </Box>
          {/* <Button onClick={() =>
      createFakeData(gun, user)
    }>Test the System! </Button> */}
        </main>
      </ThemeProvider>
    </Router>
  );
}
export default App;

