import PostRender from './PostRenderer.js'

import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';

function ForumRouter(props) {
    let forums = props.forums || [];
    let user = props.user;
    let gun = props.gun;

    // Path information
    const params = useParams();
    console.log(params);
    const forum_id = params.forum_id;
    const post_id = params.post_id;
    const user_id = params.user_id;


    // Extarct relevant posts
    let relevant_posts = forums.map(x => x.posts).flat().filter(x => !forum_id || forum_id==x.parent ).filter(x => !post_id || post_id==x.hash );



    return (<PostRender posts={relevant_posts.sort(function (a, b) {
        return b.rating - a.rating;
    })} user = { user } gun = { gun } />);
}

export default ForumRouter;