import logo from './logo.png';
import './App.css';
import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ReactMarkdown from 'react-markdown'
import { Drawer, List, ListItem, ListItemText } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, Link, useParams } from 'react-router-dom';

import Comment from "./Comment.js";
const DB = require("./database.js")

// function renderChildComponent(data, gun, user) {
//     if (data.type == 'forums') {
//         return (

//             <Card sx={{ minWidth: 275, margin: "20px" }}>
//                 <Accordion>
//                     <AccordionSummary
//                         expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel1a-content"
//                         id="panel1a-header"
//                     >
//                         <h2>{(data.name || "").toString()}</h2>
//                         <i>{data.owner.slice(0, 10)}<img style={{ width: "20px", marginLeft: "10px" }} src={`https://source.boringavatars.com/beam/120/${data.owner}?colors=264653,2a9d8f,e9c46a,f4a261,e76f51`} alt="User profile" /></i>
//                         <p>{data.description}</p>
//                         <Divider />
//                     </AccordionSummary>
//                     <AccordionDetails>

//                         {(data.posts || []).map(x => renderChildComponent(x, gun, user))}
//                         <CardContent>
//                             <h2>Create a Post</h2>
//                             <TextField id="filled-basic" label="Post Title" variant="filled" onChange={(e) => { updateSetPostTitle(data.hash, e.target.value); }} /><br /><br />

//                             <TextField id="filled-basic" label="Post  Description" multiline rows={4} variant="filled" onChange={(e) => { updateCreatePostDescription(data.hash, e.target.value) }} /><br /><br />
//                             <Button onClick={() =>
//                                 createPost(data.hash, createPostTitle[data.hash], createPostDescription[data.hash], gun, user)
//                             }>Create a Post</Button>
//                         </CardContent>
//                     </AccordionDetails>
//                 </Accordion>
//             </Card>
//         )
//     }

//     if (data.type == 'posts') {
//         return (
//             <Card sx={{ minWidth: 275, margin: "20px" }}>
//                 <Accordion>
//                     <AccordionSummary
//                         expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel1a-content"
//                         id="panel1a-header"
//                     ><h3>{data.title}</h3>
//                         <i>{data.owner.slice(0, 10)}<img style={{ width: "20px", marginLeft: "10px" }} src={`https://source.boringavatars.com/beam/120/${data.owner}?colors=264653,2a9d8f,e9c46a,f4a261,e76f51`} alt="User profile" /></i>

//                         <Button onClick={() => createRating(data.hash, false, gun, user)}>👎</Button><b>{data.rating}</b><Button onClick={() => createRating(data.hash, true, gun, user)}>👍</Button>

//                     </AccordionSummary>
//                     <AccordionDetails>
//                         <ReactMarkdown>
//                             {data.description}
//                         </ReactMarkdown>
//                         <Divider />
//                         {(data.comments || []).map(x => renderChildComponent(x, gun, user))}
//                         <CardContent>
//                             <h2>Create a Comment</h2>

//                             <TextField id="filled-basic" label="Comment" multiline rows={4} variant="filled" onChange={(e) => { updateCreateCommentBody(data.hash, e.target.value) }} /><br /><br />
//                             <Button onClick={() =>
//                                 createComment(data.hash, createCommentBody[data.hash], gun, user)
//                             }>Create a Comment</Button>

//                         </CardContent>
//                     </AccordionDetails>

//                 </Accordion>


//             </Card>
//         )
//     }
//     if (data.type == 'comments') {
//         return (
//             <Card sx={{ minWidth: 275, margin: "20px" }}>
//                 <Accordion>
//                     <AccordionSummary
//                         expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel1a-content"
//                         id="panel1a-header"
//                     >
//                         <p>{data.comment}</p>
//                         <i>{data.owner.slice(0, 10)}<img style={{ width: "20px", marginLeft: "10px" }} src={`https://source.boringavatars.com/beam/120/${data.owner}?colors=264653,2a9d8f,e9c46a,f4a261,e76f51`} alt="User profile" /></i>
//                         <Divider />
//                         <Button onClick={() => createRating(data.hash, false, gun, user)}>👎</Button><b>{data.rating}</b><Button onClick={() => createRating(data.hash, true, gun, user)}>👍</Button>
//                         <Divider />
//                     </AccordionSummary>
//                     <AccordionDetails>
//                         {(data.comments || []).map(x => renderChildComponent(x, gun, user))}
//                         <CardContent>
//                             <h2>Create a Comment</h2>

//                             <TextField id="filled-basic" label="Comment" multiline rows={4} variant="filled" onChange={(e) => { updateCreateCommentBody(data.hash, e.target.value) }} /><br /><br />
//                             <Button onClick={() =>
//                                 createComment(data.hash, createCommentBody[data.hash], gun, user)
//                             }>Create a Comment</Button>
//                         </CardContent>
//                     </AccordionDetails>

//                 </Accordion>

//             </Card>
//         )
//     }

// }

function PostRender(props) {

    let posts = (props.posts || []);
    let user = props.user;
    let gun = props.gun;



    return (<Box>
        {posts.map(function (post) {
            return (
            <Card sx={{ minWidth: 275, margin: "20px" ,padding:"20px"}}>
                <Link to={`/post/${encodeURIComponent(post.hash)}`} style={{ textDecoration: 'none' }}> 
                <p>p2peddit/{post.forum}</p>
                <h3>{post.title}</h3><i>{post.owner.slice(0, 10)}<img style={{ width: "20px", marginLeft: "10px" }} src={`https://source.boringavatars.com/beam/120/${post.owner}?colors=264653,2a9d8f,e9c46a,f4a261,e76f51`} alt="User profile" /></i>
                </Link>


                <ReactMarkdown>
                    {post.description}
                </ReactMarkdown>
                <Button onClick={() => DB.createRating(post.hash, false, gun, user)}>👎</Button><b>{post.rating}</b><Button onClick={() => DB.createRating(post.hash, true, gun, user)}>👍</Button>
            
                <Comment comments = {post.comments || []} user = { user } gun = { gun } />
            </Card>

            );
        })}


    </Box>)
}
export default PostRender;