import './App.css';
import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ReactMarkdown from 'react-markdown'
import { Drawer, List, ListItem, ListItemText } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, useParams, Link } from 'react-router-dom';



const DB = require("./database.js")

function Comment(props){
    let comments = props.comments || [];
    let user = props.user;
    let gun = props.gun;

    return (<Card>
        {comments.map(function(comment){
            return (
            <Card sx={{ minWidth: 275, margin: "20px" }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p>{comment.comment}</p>
                <i>{comment.owner.slice(0, 10)}<img style={{ width: "20px", marginLeft: "10px" }} src={`https://source.boringavatars.com/beam/120/${comment.owner}?colors=264653,2a9d8f,e9c46a,f4a261,e76f51`} alt="User profile" /></i>
                <Divider />
                <Button onClick={() => DB.createRating(comment.hash, false, gun, user)}>👎</Button><b>{comment.rating}</b><Button onClick={() => DB.createRating(comment.hash, true, gun, user)}>👍</Button>
                <Divider />
              </AccordionSummary>
              <AccordionDetails>
                <Comment comments = {comment.comments || []} user = { user } gun = { gun } />
                <CardContent>
                  {/* <h2>Create a Comment</h2>
   */}
                  {/* <TextField id="filled-basic" label="Comment" multiline rows={4} variant="filled" onChange={(e) => { updateCreateCommentBody(comment.hash, e.target.value) }} /><br /><br />
                  <Button onClick={() =>
                    createComment(comment.hash, createCommentBody[comment.hash], gun, user)
                  }>Create a Comment</Button> */}
                </CardContent>
              </AccordionDetails>
  
            </Accordion>
  
          </Card>
            );
        })}
    </Card>);

}

export default Comment;